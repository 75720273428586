.otp-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    font-family: 'system-ui';
  }
  
  .otp-container {
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    padding-top: 0px;
    text-align: center;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    width: 360px;
    height: 340px;
  }
  
  .otp-container h2 {
    margin: 0;
    font-size: 25px;
    color: #333333;
  }
  
  .otp-container p {
    font-size: 0.9rem;
    color: #615d5d;
    margin: 10px 0;
  }
  
  .otp-inputs {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
  }
  
  .otp-input {
    width: 40px;
    height: 50px;
    text-align: center;
    font-size: 1.2rem;
    border: 1px solid #cccccc;
    border-radius: 4px;
    outline: none;
    transition: border 0.3s ease-in-out;
  }
  
  .otp-input:focus {
    border-color: #007bff;
  }
  
  .otp-resend {
    font-size: 0.8rem;
    color: #999999;
    margin-top: 30px !important;
  }
  
  .otp-resend span {
    color: #111111;
    cursor: pointer;
  }
  
  .otp-resend span:hover {
    text-decoration: underline;
  }
  
  .otp-verify-btn {
    width: 100%;
    background-color: #007A6E;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    padding: 10px 0;
    font-size: 1rem;
    cursor: pointer;
    /* transition: background-color 0.3s ease-in-out; */
    margin-top: 20px;
    height: 50px;
    font-size: 20px;
  }
  
  .otp-verify-btn:hover {
    background-color: #01655b;
    transition: 3ms;
  }

  .otp-inp-parent{
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
}

.otp-box {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.2s ease-in-out;
    margin:0px 6px;
    background-color: #f5f4f6;
    cursor: pointer;
}

.otp-box:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.otp-box::-webkit-inner-spin-button,
.otp-box::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.close-btn{
  text-align: right;
  margin-right: -12px;
  margin-top: 5px;
  margin-bottom: -50px;
}

.otp-container #diff-user{
  margin-top: -5px;
    font-size: 11px;
    text-decoration: underline;
    margin-bottom: 20px;
    cursor: pointer;
}

.signIn-submit-btn-central{
  padding: 8px 0px;
  width: 180px;
  background-color: #007A6E;
  margin: 15px auto;
  display: block;
  margin-bottom: 5px;
}

.signIn-submit-btn-central:hover {
  background-color: #01655b;
  transition: 3ms;
}
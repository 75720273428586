.consolidated-data {
    width: 400px;
    margin: 50px auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
    font-family: Arial, sans-serif;
}

.controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
}

select {
    padding: 8px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
}

button {
    padding: 10px 15px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

.count-box {
    margin-top: 20px;
    padding: 15px;
    background-color: #f8f9fa;
    border: 2px solid #007bff;
    border-radius: 8px;
}

.count-box h3 {
    margin: 0;
    color: #007bff;
}

.count-box p {
    font-size: 22px;
    font-weight: bold;
    margin: 10px 0 0;
}

.scrollable-td {
    max-width: 370px;
    max-height: 100px;
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    padding: 5px;
}

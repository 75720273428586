.custom-modal-bank .modal-content {
  position: relative;
  min-width: 400px;
  width: 100%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* Ensures the popup content is above the overlay */
  text-align: left;
  min-height: 320px;
}

.custom-modal-bank{
  min-width: 70% !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.custom-modal-bank .modal-header {
  border-bottom: none;
}

.custom-modal-bank .modal-header h3 {
  margin: 0;
}

.custom-modal-bank .modal-footer {
  border-top: none;
}

.custom-modal-bank .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;
}

.custom-modal-bank .close-btn:hover {
  color: #007bff;
}

.custom-modal-bank .timeline-container {
  display: flex;
  flex-direction: column-reverse; /* Ensures bottom-to-top progress */
  /* padding-left: 30px; Space for the vertical line */
}

.custom-modal-bank .timeline-step {
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
  position: relative;
}
.custom-modal-bank .col-1{
width:8.33333333% !important;
}

.custom-modal-bank .step-details {
  padding-left: 15px;
  margin: 10px 0px;
}

.custom-modal-bank .step-details p {
  margin: 5px 0 0;
  font-size: 0.9em;
  color: #666;
}

.custom-modal-bank .step-details .content {
  font-size: 12px;
}

.custom-modal-bank .vertical-line1 {
  height: 30%;
  width: 2px; 
  background-color: #d0d0d0; 
}

.custom-modal-bank .vertical-line2 {
  width: 2px; 
  height: 37%;
  background-color: #d0d0d0; 
}

/* Container for the circles and vertical lines */
.custom-modal-bank .circle-container {
  display: flex;
  align-items: center;
  height: 38px;
  justify-content: center;
  position: relative; /* Allows vertical lines to be positioned absolutely */
}

/* Style for the bigger circle */
.custom-modal-bank .big-circle {
  width: 15px; /* Size of the bigger circle */
  height: 15px;
  border-radius: 50%;
  background-color: #DBE9FE; /* Color of the bigger circle */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Allows positioning of the smaller circle inside */
  z-index: 1;
}

/* Style for the smaller circle */
.custom-modal-bank .small-circle {
  width: 7px; /* Size of the smaller circle */
  height: 7px;
  border-radius: 50%;
  background-color:#053977; /* Darker color for the smaller circle */
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; /* Positioned over the bigger circle */
}

/* Style for the text inside the smaller circle */
.custom-modal-bank .circle-text {
  color: #fff; /* Text color */
  font-weight: bold;
}

.loanTable{
  padding: 6px;
  border: 1px solid;
}

.days-difference-box {
  position: absolute;
  margin-top: -15px;
  padding: 4px;
  background-color: #f9f9f9; 
  border-radius: 8px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  border: 1px solid #ddd; 
  color: #333; 
  font-size: 14px; 
  font-weight: bold; 
  text-align: center; 
  width: auto; 
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#capitalIconStyle{
  border-radius: 11.231px;
  background-color: #FCF1ED;
  height: 56px;
width:56px;
display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

#assetIconStyle{
  border-radius: 11.231px;
background: #EDF1FC;
box-shadow: 0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04);
height: 56px;
width:56px;
display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

#machineryIconStyle{
  border-radius: 11.231px;
background: #FCF9ED;
box-shadow: 0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04);
height: 56px;
width:56px;
display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.viewOfferBox{
  display: inline-flex;
padding: 12px 33px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 7px;
background: linear-gradient(92deg, #1B56AC 2.95%, #28B1BE 100%);
}

.viewOfferText{
  color: #FFF;
font-family: Arial;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 28px; /* 175% */
letter-spacing: 0.5px;
text-transform: capitalize;
margin-bottom: 0px;
}

.keyFeaturesBL{
  color: #1C58AD;
font-family: Arial;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 26px; /* 144.444% */
}

#noCollateralStyle{
  border-radius: 11.231px;
background: #EDF8FC;
box-shadow: 0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04);
height: 56px;
width:56px;
display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

#worthinessIconStyle{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
height: 56px;
flex-shrink: 0;
border-radius: 11.231px;
background: #ECECEC;
box-shadow: 0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04);
}

#section3{
  border-radius: 32px;
background: #F5FAFF;
margin-right: 26px;
height: 800px;
max-width: 100%;
margin-top:52px;
}

#lendersLogoBoxStyle{
  display: flex;
  align-items: center;
  justify-content: center;
width: 110px;
height: 44px;
flex-shrink: 0;
border-radius: 6px;
border: 1px solid #D5EAFF;
background: #FFF;
background-blend-mode: soft-light;
box-shadow: 2.11974px 4.23947px 31.08947px 0px rgba(46, 49, 146, 0.04);
}

#section4{
height: 426px;
flex-shrink: 0;
border-radius: 32px;
background: #FFFBF3;
margin-right: 26px;
margin-top:65px;
}

#collateralRequiredStyle{
  width: 56px;
height: 56px;
flex-shrink: 0;
border-radius: 11.231px;
background: #E9F5EE;
box-shadow: 0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04);
display: flex;
  align-items: center;
  justify-content: center;
}

#lowerRatesStyle{
  width: 56px;
height: 56px;
flex-shrink: 0;
border-radius: 11.231px;
background: #EDF1FC;
box-shadow: 0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04);
display: flex;
  align-items: center;
  justify-content: center;
}

#prepayementIconStyle{
  border-radius: 11.231px;
background: #F3EDF7;
box-shadow: 0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04);
width: 56px;
height: 56px;
flex-shrink: 0;
display: flex;
  align-items: center;
  justify-content: center;
}

.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color:black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

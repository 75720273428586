.pi.thanks {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 20px;
    z-index: 1000;
    width: 90%; /* Responsive width */
    max-width: 500px; /* Maximum width for larger screens */
    text-align: center;
}

.pi.thanks  .text-parent {
    margin-bottom: 20px;
}

.pi.thanks  .text-parent img {
    margin-bottom: 10px;
}

.pi.thanks  #text-1 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.pi.thanks  #text-2 {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
}

.pi.thanks  .safety {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
}

.pi.thanks  .safety img {
    width: 24px;
    height: 24px;
}

.pi.thanks  #safety-text {
    font-size: 14px;
    color: #888;
}

/* Optional: Dark background overlay */
.pi.thanks body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999; /* Ensure it appears behind the popup */
}

.pi.thanks .close-btn {
    position: absolute;
    top: -13px;
    right: 27px;
    font-size: 40px;
    cursor: pointer;
    color: #333;
}

body {
  font-family: arial;
}

section,
header,
footer,
.terms {
  min-width: 668px;
}

a {
  text-decoration: none;
  color: currentColor;
}

a:hover {
  text-decoration: underline;
  color: currentColor;
}

.container {
  max-width: 1230px;
}

.header {
  width: 100%;
  height: 85px;
  left: 0px;
  top: 0px;
}

.title {
  background: none !important;
  color: #091E42;
  -webkit-text-fill-color: #091E42;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  font-family: 'Arial';
}

.title span {
  letter-spacing: -0.035em;
  font-feature-settings: 'cv10' on, 'cv06' on, 'cv05' on, 'cv01' on, 'cpsp' on;
  background: linear-gradient(90deg, #1A54AB 24.18%, #28B6BF 84.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
}

.loanTapTitle {
  background: none !important;
  color: #091E42;
  -webkit-text-fill-color: #091E42;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  font-family: 'Arial';
}

.loanTapTitle span {
  letter-spacing: -0.035em;
  font-feature-settings: 'cv10' on, 'cv06' on, 'cv05' on, 'cv01' on, 'cpsp' on;
  background: linear-gradient(90deg, #1A54AB 21.18%, #28B6BF 100.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
}

.iiflTitle {
  background: none !important;
  color: #091E42;
  -webkit-text-fill-color: #091E42;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  font-family: 'Arial';
}

.creditenableTitle {
  background: none !important;
  color: #091E42;
  -webkit-text-fill-color: #091E42;
  font-weight: 400;
  font-size: 32px;
  line-height: 100%;
  font-family: 'Arial';
}

.iiflTitle span {
  letter-spacing: 0.05rem;
  font-feature-settings: 'cv10' on, 'cv06' on, 'cv05' on, 'cv01' on, 'cpsp' on;
  background: linear-gradient(90deg, #1A54AB 21.18%, #28B6BF 100.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
}

.creditenableTitle span {
  letter-spacing: -.05rem;
  font-feature-settings: 'cv10' on, 'cv06' on, 'cv05' on, 'cv01' on, 'cpsp' on;
  background: linear-gradient(90deg, #1A54AB 21.18%, #28B6BF 100.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
}

.sec02 .aboutlender {
  color: #091E42;
  font-weight: 400;
  font-size: 18px;
  font-style: normal;
  line-height: 28px;
  font-family: 'Arial';
}

.sec02 .lenderheader {

  color: #294A82;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}

.sec02 .benefits {
  color: #091E42;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 140% */
}

.sec07 span {
  font-family: Arial;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 32px */
  text-transform: capitalize;

  background: linear-gradient(91deg, #1A54AB 0%, #28B6BF 103.91%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sec07 .productname {

  color: #091E42;
  font-family: Arial;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 32px */
  text-transform: capitalize;

}

#capitalIconStyle {
  border-radius: 11.231px;
  background-color: #FCF1ED;
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

#assetIconStyle {
  border-radius: 11.231px;
  background: #EDF1FC;
  box-shadow: 0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04);
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

#machineryIconStyle {
  border-radius: 11.231px;
  background: #FCF9ED;
  box-shadow: 0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04);
  height: 56px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.slick-next:before,
.slick-prev:before {
  color: #3e4053 !important;
  font-size: 22px !important;
  margin-left: -20px;
}

/* .slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 27px !important;
    line-height: 1;
    opacity: .75;
    color:#3e4053 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } */

.custom-dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown:hover #mainDropdown {
  display: block;
}

#mainDropdown {
  top: 30px !important;
  left: -112px !important;
}

#ourLendersDropdown::after {
  display: none !important;
  color: #000;
}

#ourLendersStyle {
  top: 70px;
  left: -158px !important;
}

.custom-dropdown button {
  background: none;
  border: none;
  color: rgb(48, 49, 140);
  font-weight: 550;
  text-decoration: none;
  cursor: pointer;
}

.custom-dropdown button:hover,
.custom-dropdown button:focus {
  color: #000;
}

.custom-dropdown #arrowIcon {
  color: rgb(48, 49, 140);
}



.dropdown-item {
  color: rgb(48, 49, 140);
  font-weight: 550;
}

.dropdown-item:hover {
  text-decoration: none;
  background-color: #e9ecef;
}

.custom-dropdown .btn-link {
  margin-left: -12px;
  /* Adjust the negative margin */
}

.dropdown-toggle::after {
  display: none;
}

.sec01 {
  font-family: 'Open Sans';
}

.sec01 h2 {
  line-height: 40px;
  letter-spacing: -0.035em;
  font-feature-settings: 'cv10' on, 'cv06' on, 'cv05' on, 'cv01' on, 'cpsp' on;
  background: linear-gradient(90.68deg, #1A54AB 1.32%, #28B6BF 78.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 0;
  margin-top: 17px;
}

.sec01 h2 span {
  background: none !important;
  color: #000;
  -webkit-text-fill-color: black;
  font-weight: 400;
}

section .container {
  padding: 10px 10px;
}

.sec01 .logo .row>div {
  border: 1px solid #C2C5CA;
  padding: 5px;
  margin-right: 7%;
  margin-top: 20px;
  max-width: 119px;
  max-height: 58px;

}

.sec01 .logo img {
  background: #F5F9FF;
  max-width: 100%;
  height: auto;
  min-height: 40px;
}

.sec01 .content .row span {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #052F5F;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 26px;
}

.sec01 .lftcontent>div {
  max-width: 200px;
}

.sec01 .content .row>div {
  margin-top: 20px;
  margin-right: 20px;
}

.sec01 .content .row img {
  float: left;
  padding-top: 3px;
  padding-right: 8px;
  height: auto;
  max-width: 100%;
}

.title {
  background: none !important;
  color: #000;
  -webkit-text-fill-color: black;
  font-weight: 400;
  font-size: 30px;
  line-height: 40px;
  /* //margin-bottom: 20px; */
}

.title_limit {

  color: #bb2424;
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 20px;
  font-weight: 700;
}

.title_offer {

  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 0%, rgba(0, 212, 255, 1) 79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 20px;
  font-weight: 700;
}

.title_offer span {
  letter-spacing: -0.035em;
  font-feature-settings: 'cv10' on, 'cv06' on, 'cv05' on, 'cv01' on, 'cpsp' on;
  background: linear-gradient(90.68deg, #1A54AB 1.32%, #28B6BF 78.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
}

.title span {
  letter-spacing: -0.035em;
  font-feature-settings: 'cv10' on, 'cv06' on, 'cv05' on, 'cv01' on, 'cpsp' on;
  background: linear-gradient(90.68deg, #1A54AB 1.32%, #28B6BF 78.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
}

.sec02 .unsec_loan {
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  max-width: 403px;
  height: auto;
  font-family: 'Arial';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 100%;
  background: #EAEAF3;
  border-radius: 8px 0px 0px 8px;
  border: 1px solid #2E3192;
  height: 35px;
  color: #091E42;
}

.sec02 .unsec_loan button {
  border: none;
  background: transparent;
}

.sec02 .btn_active,
.sec02 .btn_active button {
  background: #2E3192;
  color: #FFFFFF;
}

.sec02 h3 {
  margin: 5px 0;
  margin-bottom: 15px;
}

#BLU-Tab .col-12,
#BLS-Tab .col-12,
#LAP-Tab .col-12 {
  padding: 0 5%;
}

#BLU-Tab .col-12 .row div:nth-child(1),
#BLS-Tab .col-12 .row div:nth-child(1),
#LAP-Tab .col-12 .row div:nth-child(1) {
  max-width: 200px;
  /* // min-width: none; */
}

#BLU-Tab .col-12 .row div:nth-child(2),
#BLS-Tab .col-12 .row div:nth-child(2),
#LAP-Tab .col-12 .row div:nth-child(2),
#BLU-Tab .col-12 .row div:nth-child(3),
#BLS-Tab .col-12 .row div:nth-child(3),
#LAP-Tab .col-12 .row div:nth-child(3),
#BLU-Tab .col-12 .row div:nth-child(4),
#BLS-Tab .col-12 .row div:nth-child(4),
#LAP-Tab .col-12 .row div:nth-child(4),
#BLU-Tab .col-12 .row div:nth-child(5),
#BLS-Tab .col-12 .row div:nth-child(5),
#LAP-Tab .col-12 .row div:nth-child(5) {
  max-width: 160px;
  min-width: 160px;
}

#BLU-Tab .col-12 .row div:nth-child(6),
#BLS-Tab .col-12 .row div:nth-child(6),
#LAP-Tab .col-12 .row div:nth-child(6) {
  max-width: 140px;
}

#BLU-Tab .col-12 .row div:nth-child(7),
#BLS-Tab .col-12 .row div:nth-child(7),
#LAP-Tab .col-12 .row div:nth-child(7) {
  max-width: 155px;
}

/* #BLU-Tab .col-12 .row div:nth-child(7) span,
#BLS-Tab .col-12 .row div:nth-child(7) span,
#LAP-Tab .col-12 .row div:nth-child(7) span {
  min-width: 115px;
  max-width: 100% !important;
  display: block !important;
  margin-left: 32% !important;
  height: 40px;
} */

/* #BLU-Tab .col-12 .row div:nth-child(3) span, #BLS-Tab .col-12 .row div:nth-child(3) span, #LAP-Tab .col-12 .row div:nth-child(3) span, #BLU-Tab .col-12 .row div:nth-child(4) span, #BLS-Tab .col-12 .row div:nth-child(4) span, #LAP-Tab .col-12 .row div:nth-child(4) span, #BLU-Tab .col-12 .row div:nth-child(5) span, #BLS-Tab .col-12 .row div:nth-child(5) span, #LAP-Tab .col-12 .row div:nth-child(5) span {
   font-weight: 700;
} */

.sec02 .tabBenifit {
  margin-top: 10px;
  margin-bottom: 15px;
}


.sec02 .tabBenifit .col-3 {
  max-width: 253px;
  border-right: 1px solid #dee3e9;
}

.sec02 .tabBenifit .col-3:last-child {
  border: none;
}

.sec02 .tabBenifit span {
  color: #2E3192;
  font-size: 18px;
  font-weight: 700;
  padding-right: 3%;
  padding-left: 3%;
}

.sec02 .tabBenifit span .small {
  color: #727C9E;
  font-size: 12px;
  font-weight: normal;
  display: inline-block;
  top: -2px;
  position: relative;
}

.sec02 .tab .table th,
.sec02 .tab .table td {
  text-align: left;
  font-size: 14px;
  border: 1px solid #CAD2DB;
}

.sec02 .tab .thead {
  text-align: left;
  background: #F8F9FA;
  /* border-radius: 8px; */
  height: 45px;
  line-height: normal;
  /* box-shadow: 0px 24px 40px rgba(5, 47, 95, 0.08); */
  font-size: 14px;
  font-weight: 400;
  color: var(--Grey-1, #4C4C4C);
  font-family: arial;
  /* padding-top: 15px ; */
}

.sec02 .tab .thead div {
  border-right: 1px solid #E3F0FF;
  height: 70px;
  padding-top: 12px;
}

.sec02 .tab .tbody {
  text-align: left;
  font-size: 14px;
  margin-top: 10px;
  /* margin-bottom: 10px; */
  /* height: 130px; */
  overflow: hidden;
  color: #091E42;
  font-family: arial;
  background-blend-mode: soft-light;
  border: 1px solid #EBEEF9;
  box-shadow: 0px 2px 4px rgba(5, 47, 95, 0.08);
  /* border-radius: 8px 8px 12px 12px; */
}

.sec02 .tab .tbody i {
  font-size: 13px;
}

.sec02 .tab .tbody .list-unstyled {
  margin-top: 10px;
  margin-bottom: 0;
  line-height: 22px;
}

.sec02 .tab .tbody div {
  min-height: 28px;
}

.sec02 .tab .tbody span {
  display: table-cell;
  /* height: 85px; */
  vertical-align: middle;
  padding: 20px;
  margin: 0;
}

.sec02 .tab .tbody span small {
  font-size: 10px;
  font-weight: normal;
  color: #727C9E;
}

.sec02 .tab .img-thumbnail {
  max-width: 132px;
  border-radius: 4px;
  /* margin-top: 15px; */
  width: 100%;
  border: 0.5px solid #EBEEF9;
  height: 55px;
}

.btnApply {
  text-align: center;
  display: block !important;
  background: #49A399;
  max-width: 140px !important;
  margin: 0 auto !important;
  /* margin-top: 24px !important; */
  color: #fff;
  border-radius: 8px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  cursor: pointer;
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
}

.appBtnApply {
  align-items: center;
  display: flex;
  justify-content: center;
  background: #49A399;
  max-width: 140px !important;
  margin-top: 27px ;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  height: 41px;
}

.appBtnApply:hover {

  color: #fff;
  background: #007A6E;
}

.appBtnApplyProcess {
  align-items: center;
  display: flex;
  justify-content: center;
  background: #007A6E;
  max-width: 140px !important;
  margin-top: 33px ;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  height: 41px;
  font-size: 13px;
  position: relative; 
  overflow: hidden;
}

.appBtnApplyProcess:hover {

  color: #fff;
  background: #007A6E;
}

.appBtnApplyRejected {
  align-items: center;
  display: flex;
  justify-content: center;
  background: #787878;
  background: #787878;
  max-width: 140px !important;
  margin-top: 22px ;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  height: 41px;
}

.appBtnApplyRejected:hover {
  color: white;
  background: #666666;
  background: #666666;
}

.btnApply:hover {

  color: #fff;
  background: #007A6E;
}

.sec02 .offer {
  /* background: #C2F0FB; */
  justify-content: center;
  align-items: center;
  font-size: 10px;
  line-height: 10px;
  margin-left: 0px;
  /* min-width: 1135px; */
  height: 20px;
  margin-top: -10px;

  color: #757575;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  min-height: 25px;
  text-align: center;
}

.knowloan_unsec_loan,
.loandesc {
  border: 1px solid #EBEEF9;
  border-radius: 8px;
  background: #fff;
  margin-top: 10px;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
}

.knowloan_unsec_loan ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.knowloan_unsec_loan li {
  list-style: none;
  float: left;
  width: 100%;
  clear: both;
  margin-bottom: 10px;
}

.knowloan_unsec_loan button {
  font-size: 16px;
  border: none;
  width: 100%;
  background: none;
  line-height: 40px;
}

.knowloan_unsec_loan .btn_active {
  background: #F1F3FE;
  font-weight: 700;
  border-radius: 8px;
}

.knowloan_unsec_loan button img {
  float: left;
  max-width: 40px;
}

.knowloan_unsec_loan a {
  color: #49A399;
  font-size: 12px;
  font-weight: 700;
}

.btnExplore {
  text-align: center;
  background: #49A399;
  color: #fff;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  float: right;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  width: 95px;
  height: 44px;
  line-height: 24px;
}

.btnExplore:hover {

  color: #fff;
  background: #49a399c9;
}

.loandesc h3 {
  font-size: 20px;
  text-align: left;
}

.features {
  text-align: left;
}

.features img {
  float: left;
}

.features span {
  display: inline-block;
  padding-left: 34px;
  margin-top: 8px;
}

.features .col-md-3,
.features .col-sm-6,
.features .col-lg-3,
.features .col-lg-5 {
  background-repeat: no-repeat !important;
  height: 44px;
  margin-bottom: 20px;
}

.features h3 {
  padding: 0;
}

.features h3,
.eligibility h3,
.documents h3,
.howWorks h3 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.howWorks img {
  max-width: 100%;
}

.howWorks {
  font-size: 14px;
}

.eligibility .col-lg-6 {
  margin-bottom: 20px;
}

.documents {
  text-align: left;
  font-size: 14px;
}

.documents .note {
  background: url(../src/Images/Vector.png);
  background-repeat: no-repeat;
  padding: 5px 5px 5px 40px;
  background-color: #EFF3FA;
  border-radius: 8px;
  background-position: 6px 7px;
  font-style: italic;
  font-size: 12px;
  color: #000;
  max-width: 98%;
}

.documents .col-12 {
  margin-bottom: 10px;
}

.loandesc .box {
  max-height: 480px;
  padding-bottom: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  padding-left: 12px;
}

.loan_card_heading {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  color: #000000;
  margin-bottom: 20px;
  margin-top: 10px;
}

.range_elem {
  width: 100%;
  color: #49A399;
  --thumb-height: 10px;
  --thumb-width: 10px;
  --track-height: 2px;
  --track-color: #EBEEF9;
  /* --brightness-hover: 180%; */
  /* --brightness-down: 80%; */
  --clip-edges: 0.125em;
  position: relative;
  background: #fff0;
  overflow: hidden;
  opacity: 0.7;
}



/* === WebKit specific styles === */
.range_elem,
.range_elem::-webkit-slider-runnable-track,
.range_elem::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: auto;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

.range_elem::-webkit-slider-runnable-track,
.range_elem::-webkit-slider-thumb {
  position: relative;
}

.range_elem::-webkit-slider-thumb {
  --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
  --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
  --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
  --clip-further: calc(100% + 1px);
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0 100vmax currentColor;

  width: var(--thumb-width, var(--thumb-height));
  background-color: #49A399;
  box-shadow: var(--box-fill);
  border-radius: var(--thumb-width, var(--thumb-height));

  /* filter: brightness(100%); */
  clip-path: polygon(100% -1px,
      var(--clip-edges) -1px,
      0 var(--clip-top),
      -100vmax var(--clip-top),
      -100vmax var(--clip-bottom),
      0 var(--clip-bottom),
      var(--clip-edges) 100%,
      var(--clip-further) var(--clip-further));
}

.range_elem::-webkit-slider-runnable-track {
  background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center / 100% calc(var(--track-height) + 1px);
}

.range-labels {
  /* margin: 18px -41px 0; */
  padding: 0;
  list-style: none;
  padding: 0 12px;
  margin: 0;
}

.range-labels>li:first-child {
  text-align: left;
}

.range-labels li {
  position: relative;
  float: left;
  width: 50% !important;
  text-align: center;
  color: #b2b2b2;
  font-size: 12px;
  cursor: pointer;
}

.loanTitle {
  font-weight: 700;
  font-size: 16px;
  color: #000;
  text-align: left;
  margin-bottom: 10px;
}

.loanVal {
  font-size: 16px;
  color: #000;
  text-align: right;
}

.rateTxt div {
  padding-left: 26px;
  font-size: 12px;
  color: #909090;
}

.explore_btn {
  font-weight: 700;
  padding: 12px 21px;
  width: 95px;
  height: 44px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  background: #49A399;
  border-radius: 8px;
  border: none;
  margin-top: 10px;
}

.explore_btn:hover {

  color: #ffffff;
  background: #49a399c9;
}

.sugg {
  background: #F4F4F4;
  font-size: 14px;
  border-radius: 8px;
  padding: 10px 0;
  margin-left: 10px;
}

.loanfit {
  margin-top: 5px;
  background: #fff;
  border-radius: 8px;
  padding: 30px;
  max-width: 544px;
}

.loanfit .row {
  margin-bottom: 30px;
}

.amtVal {
  border: 1px solid #EBEEF9;
  padding: 5px;
  border-radius: 8px;
}

.intVal {
  border: 1px solid #EBEEF9;
  padding: 5px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: inline-block;
  height: 36px;
  text-align: center;
}

.intIco {
  background: #49A399;
  padding: 5px;
  color: #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 35px;
  height: 36px;
  display: inline-block;
  text-align: center;
}

.sec06 .row span {
  background: #fff;
  display: inline-block;
  padding: 10px 25px;
  border-radius: 8px;
  width: 160px;
  height: 64px;
}

.sec06 span img {
  /* max-width: 130px; */
  width: 100px;
  height: 40px;
  padding: auto;
}

footer {
  background: #30318C;
  width: 100%;
  height: 80px;
}

footer ul {
  list-style: none;
  margin: 0 auto;
  max-width: 580px;
  text-align: center;
}

footer li {
  float: left;
  color: #fff;
  font-size: 20px;
  padding: 4%;
}

footer li a {
  color: #fff;
}

.terms {
  background: #202264;
  color: #fff;
  font-size: 12px;
  padding-top: 10px;
  height: 35px;
  overflow: hidden;
}

.terms ul {
  list-style: none;
  float: right;
}

.terms li {
  float: left;
  padding-left: 40px;
  cursor: pointer;
}

/* Modal */
.modal-title {
  font-size: 32px;
  line-height: 30px;
  text-transform: capitalize;
  margin: 0 auto;
  text-align: center;
}

#compareloan .modal-title {
  font-size: 32px !important;
  line-height: 30px;
  text-transform: capitalize;
  margin: 0 auto;
  text-align: center;
}

.modal-content {
  background: #F8F9FA;
  font-size: 16px;
  /* padding: 20px; */
}

.modal-header .btn-close {
  position: absolute;
  right: 20px;
  margin-top: 0px;
}

.modal-title span {
  letter-spacing: -0.035em;
  font-feature-settings: 'cv10' on, 'cv06' on, 'cv05' on, 'cv01' on, 'cpsp' on;
  background: linear-gradient(90.68deg, #1A54AB 1.32%, #28B6BF 78.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.modal-content {
  background: #F8F9FA;
  font-size: 16px;
}

.modal-content .unset {
  margin-top: 80px;
}

.modal-content .unset li {
  border: none;
  background: url(../src/Images/line.png);
  background-repeat: no-repeat;
  background-position: 20px 43px;
  line-height: 30px;
  font-weight: 700;
}

.modal-header {
  border: none;
}

.modal-content .unset li:last-child {
  background: none;
}

.modal-content .set li {
  border: none;
  background-image: url(../src/Images/line.png);
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: 20px 43px;
  line-height: 30px;
}

.modal-content .set li:last-child,
.modal-content .set li:first-child {
  background-image: none;
}

.modal-content .active {
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  height: 80px;
  padding-top: 15px;
  /* max-width: 800px; */

}

.sec04 .appl {

  background: linear-gradient(91.33deg, #2E3092 -25.79%, #A6A6D6 115.38%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 24px;

}

.slick-dots:has(> li:first-child.slick-active) {
  display: none !important;
}

.lenderfilters .dropdown-menu .form-group .form-check-label {
  padding-left: 5px;
  line-height: 21px;
  position: absolute;
}

@media (max-width: 1250px) {
  .container.sec05 {
    background-position: center;
    background-size: contain;
    min-height: 260px;
  }
}


@media (max-width: 1250px) {

  #BLU-Tab .col-12,
  #BLS-Tab .col-12,
  #LAP-Tab .col-12 {
    padding: 0 5%;
  }

  #BLU-Tab .col-12 .row div:nth-child(6),
  #BLS-Tab .col-12 .row div:nth-child(6),
  #LAP-Tab .col-12 .row div:nth-child(6) {
    max-width: 212px;
  }

  /* #BLU-Tab .col-12 .row div:nth-child(6), #BLS-Tab .col-12 .row div:nth-child(6), #LAP-Tab .col-12 .row div:nth-child(6) {
  max-width: 250px;
}
#BLU-Tab .col-12 .row div:nth-child(4), #BLS-Tab .col-12 .row div:nth-child(4), #LAP-Tab .col-12 .row div:nth-child(4) {
  min-width: 176px;
} */
}

@media (max-width: 1110px) {
  .loanfit {
    max-width: 485px;
  }
}

@media (max-width: 991.98px) {
  .loanfit {
    max-width: 100%;
  }

  .knowloan_unsec_loan,
  .loandesc {
    width: 100% !important;
    max-width: 100% !important;
  }

  .sec01 .logo {
    display: none;
  }

  .sec01 h2 {
    font-size: 30px;
    line-height: 30px;
  }

  .sec02 .unsec_loan button {
    font-size: 14px;
  }

  .lgOnly {
    display: none;
  }

  .btnApply {
    width: 80px;
    margin-left: 20px;
  }

  .knowloan_unsec_loan li {
    max-width: 100%;
  }

  .howWorks {
    text-align: left;
  }

  .loandesc .box {
    max-height: 100% !important;
  }
}

.slick-slider {
  height: 164px !important;
  background: #fff;
  float: left;
  width: 100%;
}

.slider {
  width: 100%;
  /* margin: 50px auto; */
  margin: auto;
  /* padding-top: 20px; */
}

.footer .slick-prev,
.footer .slick-next {
  margin-top: -50px
}

/* @media (min-width: 1200px) {
  .slick-list {
    padding: 0 140px !important;
  }
} */



.slick-slide .slideimg {
  width: 100%;
  padding-top: 15px;
  /* height: 100%; */
  margin: auto;
}

.slick-prev:before,
.slick-next:before {
  color: rgb(62, 64, 83) !important;

  font-size: 27px !important;

}

/* .slick-prev {

  left: 35px !important;
  z-index: 9999;
} */


/* .slick-next {

  right: 35px !important;
  z-index: 9999;

} */


.slick-slide {
  transition: all ease-in-out .3s;
  opacity: .1;
}

.slick-active {
  opacity: 1;
}

.slick-disabled {
  pointer-events: none;
  opacity: .1;
}

.slick-dots li button:before {

  font-size: 12px !important;
  line-height: 12px !important;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -5px #2E3192;
  }

  to {
    box-shadow: 0 0 10px 5px #2E3192;
  }
}

@keyframes glow1 {
  from {
    box-shadow: 0 0 10px -5px #FC8019;
  }

  to {
    box-shadow: 0 0 10px 5px #FC8019;
  }
}

.center {
  display: none;
}

.continue_s {
  background: #00a699;
  border: 1px solid #00a699;
  color: #fff;
  cursor: pointer;
  margin: 15px 0 25px;
  padding: 8px 62px;
  text-align: center;
  font-family: arial;
  border-radius: 3px;
}

.un2_s {
  font-family: arial;
  border: 1px solid #bdc7d8;
  border-radius: 5px;
  margin-top: 4px;
  outline: 0;
  letter-spacing: 0.5px;
  box-shadow: 0 -2px 0 rgba(0, 0, 0, .1) inset;
}

.em-1 {
  padding: 1px 0 5px 3px;
  color: #C33;
  clear: left;
  width: 100%;
}

.loginmodal .modal-content {

  padding: 0px;
  border: 0px;
}

.lenderfilters {

  /* background: #FFFFFF; */
  flex-shrink: 0;
  margin-bottom: 7px;
  margin-right: 38px;
  /* height: 72px; */
  text-align: left;
  align-items: center;
  padding-bottom: 7px;
}

.lenderfilters .filtertext {

  margin-left: 7px;
  color: var(--g-1, #686868);
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
}

.lenderfilters .filtercaption {

  gap: 10px;
  flex: 1 0 0;
  justify-content: space-between;
  color: var(--g-1, #686868);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
  margin-top: 5px;
  margin-bottom: 3px;
}

.lenderfilters .col-1 {

  padding: 5px !important;
}

.lenderfilters .col-3 {

  margin-left: 15px;
}

.bi.bi-funnel {
  background: linear-gradient(165deg, #2E3192 0%, #2AA699 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline-block;
}

.lenderfilters .inputboxes {

  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  justify-content: space-between;
  border-radius: 6px;
  background: #F1F1F1;
  max-width: 200px !important;
  max-height: 50px !important;
  cursor: pointer;


}

.lenderfilters input {
  border: none;
}

.lenderfilters input:focus {
  outline: none;
}

.lenderfilters .btn:focus {
  outline: none;
  box-shadow: none;
}

.lenderfilters input::placeholder {
  color: #A9A9A9;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
}

.lenderfilters .dropdown .btn {
  color: #A9A9A9;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.8;
}

.dropdown .form-group .radioclass {

  accent-color: #49A399;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.dropdown .form-group .form-check-label {
  color: #2B2B2B;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  cursor: pointer;
}

.banner-container {
  position: relative;
  max-width: 100%;
}

.diwalibanner-area {
  position: absolute;
  cursor: pointer;
}

.diwalibanner-area.area1 {
  top: 20%;
  left: 27.5%;
  width: .9%;
  height: 50%;
}

.diwalibanner-area.area2 {
  top: 60%;
  left: 21.54%;
  width: .43%;
  height: 18%;
}

.diwalibanner-area.area3 {
  top: 58%;
  left: 21.99%;
  width: .6%;
  height: 20%;
}

#ourPartnersSection {
  border-radius: 24px;
  border: 1px solid #DCDCDC;
  background: #FFF;
  height: 310px;
  flex-shrink: 0;
  margin-left: 92px;
  margin-right: 91px;

}

#ourPartnersHeading {
  text-align: center;
  font-family: Arial;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-transform: capitalize;
  background: linear-gradient(91deg, #1B56AC -5.48%, #28B1BE 46.43%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#ourPartnersPara {
  color: #4E4E4E;
  text-align: center;
  font-family: Arial;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 140% */
}

.lendersBoxStyle {
  border-radius: 8px;
  border: 1px solid #D5EAFF;
  background: #FFF;
  background-blend-mode: soft-light;
  box-shadow: 3px 6px 44px 0px rgba(46, 49, 146, 0.04);
  height: 64px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 93%;
}

.slick-next {
  z-index: 999;
}

#ourLendersDropdown::after {
  display: none !important;
  color: #000;
}

#ourLendersStyle {
  top: 70px !important;
  left: -158px !important;
}

.custom-dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown:hover #mainDropdown {
  display: block;
}

#mainDropdown {
  top: 30px !important;
  left: -116px !important;
}

.custom-dropdown button {
  background: none;
  border: none;
  color: rgb(48, 49, 140);
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.custom-dropdown button:hover,
.custom-dropdown button:focus {
  color: #000;
}

.custom-dropdown #arrowIcon {
  color: rgb(48, 49, 140);
}


/* New Changes start*/



/* .dropdown-item {
  color: rgb(48, 49, 140);
  font-weight: 550;
} */

.dropdown-item {
  color: var(--IM-Black, #1B1D1D);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.dropdown-item:hover {
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item:focus,
.dropdown-item:active {
  color: #0d6efd;
  outline: none;
}

.ourPartnersDropdown {

  color: var(--IM-Black, #1B1D1D);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.dropdown-item2 {
  color: var(--Grey-3, #757575);
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: capitalize;
  padding-left: 32px;
  padding-right: 32px;
  display: inline-block;
  width: 100%;
}

.dropdown-item2:hover {
  text-decoration: none;
  background-color: #e9ecef;
}

.dropdown-item2:focus,
.dropdown-item2:active {
  color: #0d6efd;
  outline: none;

}


/* New Changes end*/

.custom-dropdown .btn-link {
  margin-left: -12px;
  /* Adjust the negative margin */
}

#loandata .modal-content {
  align-items: flex-start;
  width: 678px;
  margin-left: -100px;
  margin-top: 130px;
}

#loandata .modal-body {
  width: 100%;
}

#loandata .btn-close {
  margin-top: -20px;
}

#loandata .lenderfilters .inputboxes {

  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  justify-content: space-between;
  border-radius: 6px;
  background: #F1F1F1;
  max-width: 280px !important;
  max-height: 50px !important;
  cursor: pointer;

}

#loandata .lenderfilters .col-6 {

  margin-left: 15px;

}

.col-1 {
  width: 12% !important;
}

.lenderfilters .loanamtbtn .col-2 {
  width: 155px;
}

.form-check {
  padding-left: 10px;
}

#loginpopupmodal .modal-content{
  width: 85%;
}



/* View Applications page Styling:- */

.progress-bar {
  width: 91.9%;
  height: 11px;
  border-radius: 57.73px;
  margin-top: 12px;
  background-color: #f0f0f0;
  position: relative;
}

.progress-dot {
  width: 3px;
  height: 3px;
  background-color: #0043CE;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.progress-dot:nth-child(1) {
  left: 25%;
  /* Adjust position based on your requirements */
}

.progress-dot:nth-child(2) {
  left: 50%;
  /* Adjust position based on your requirements */
}

.progress-dot:nth-child(3) {
  left: 75%;
  /* Adjust position based on your requirements */
}

.progress-stage {
  height: 100%;
  position: absolute;
}

.stage1 {
  background-color: #C2F0FB;
  width: 30%;
  border-radius: 57.73px;
}

.stage2 {
  background-color: #C2F0FB;
  width: 60%;
  border-radius: 57.73px;
}

.stage3 {
  background-color: #C2F0FB;
  width: 80%;
  border-radius: 57.73px;
}

.stage4 {
  background-color: #02A699;
  width: 90%;
  border-radius: 57.73px;
}
.stage5 {
  background-color: #02A699;
  width: 100%;
  border-radius: 57.73px;
}

.getDisbursal {
  height: 46px;
  width: 97.37%;
  border-radius: 6px;
  margin-top: 41px;
  position: relative;
  left: 4px;
  background-color: #02A699;
  color: white;
  font-family: Arial;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  display: grid;
  justify-content: center; 
  align-items: center;
  cursor: pointer;
}

.getDisbursal:hover{
  color: #fff;
  background: #007A6E;
}


.appBtnApply {
  align-items: center;
  display: flex;
  justify-content: center;
  background: #007A6E;
  max-width: 140px !important;
  margin-top: 27px ;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  height: 41px;
  position: relative; /* Required for positioning the pseudo-element */
  overflow: hidden;
}

.appBtnApply::after  {
  content: '';
  position: absolute;
  width: 4px;
  height: 80px;
  background: #FFF;
  animation: slidePipe 1.5s linear infinite; 
  animation-direction: alternate;
  opacity: 0.5;
  transform: rotate(135deg);
  filter: blur(6px);
}

.appBtnApplyProcess::after{
  content: '';
  position: absolute;
  width: 4px;
  height: 80px;
  background: #FFF;
  animation: slidePipe 1.5s linear infinite; 
  animation-direction: alternate;
  opacity: 0.5;
  transform: rotate(135deg);
  filter: blur(6px);
} 

@keyframes slidePipe {
  0% {
      left: -100%;
  }
  100% {
      left: 100%;
  }
}

.appBtnApply:hover {

  color: #fff;
  background: #007A6E;
}

.applicationCol {
  width: 8.333% !important;
}

.tabs{
  max-width: 140px !important;
  /* min-width: 135px !important; */
}

.allTiles{
  margin: auto 0px;
}

.blink{
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {  
  50% { opacity: 0; }
}

.mainDivLender{
    margin-top: 15px !important;
    background-color: #FFFFFF !important;
    /* box-shadow: 0.5px 0.5px 5px 2px #49A399 !important; */
}

.testimonial .slick-slide {
  margin-right: 1.2%;
  text-align: center;
  background: #F8F9FA;
  width: 6%;
  max-width: 360px;
}

.testimonial .slick-slider {
  margin-left: 20%;
}

.testimonial .slick-list {
  padding: 12px;
  margin-left: -15%;
  background: #F2F5F9;
}

.testimonial {
  font-family: Open Sans;
}

.testimonial .slick-arrow {
  z-index:1;
}

.testimonial .slick-prev{
  top:294%;
  left:27.3%;
}

.testimonial .slick-next{
  top:294%;
  right:65.7%;
}


.tabs{
  max-width: 140px !important;
  /* min-width: 135px !important; */
}

.allTiles{
  margin: auto 0px;
}

.blink{
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {  
  50% { opacity: 0; }
}

.mainDivLender{
    margin-top: 10px !important;
    background-color: #FFFFFF !important;
    /* box-shadow: 0.5px 0.5px 5px 2px #49A399 !important; */
}


.approvedBlink{
  animation: approvedBlinker 1.5s linear infinite;
}

@keyframes approvedBlinker {  
  50% { opacity: 0; }
}

.modalClose{
  font-size: 11px;
  color: #FFF;
  margin-bottom: auto;
  cursor: pointer;
}

.headingTilesHome{
  position: sticky;
  top: 45px;
  z-index: 1;
  background: rgba(255, 255, 255) !important;
}

.headingTilesExp{
  position: sticky;
  top: 45px;
  z-index: 1;
  background: rgba(255, 255, 255) !important;
}

.lenderTop{
  top:85px;
  /* position: fixed; */
  z-index: 111;
}

.headerBar{
  display: flex;
  color: rgba(76, 76, 76, 1);
  margin: auto;
}

.headerContent{
  font-family: Arial; 
  font-size: 14px; 
  font-weight: 400; 
  line-height: 16.1px;
  padding: 10px;
}

.guidance{
  text-align: center;
  background: #007A6E;
  color: #fff;
  border-radius: 6px;
  padding: 8px 14px;
  cursor: pointer;
  font-style: normal;
  max-height: 35px;
  margin: auto 0px;
  font-family: Arial;
  font-size: 14px;
  line-height: 16.1px;
}

.lenderDropdownBox{
  /* border: 1px solid; */
  position: absolute;
  background: #FFF;
  /* margin-left: 240px; */
  margin-top: 30px; 
  padding: 8px 0px;
  border-radius: 6px;
  min-width: 150px;
  box-shadow: 0px 4px 20px 0px #0000001A;
}

.ddContent{
  font-family: Arial;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.1px;
  padding: 5px 10px;
  text-decoration: none !important;
}

.appNowHeader{
  color: #007A6E;
  font-size: 11px;
  font-family: Arial;
  padding-left:9px ;
  cursor: pointer;
  font-weight: 600;
}

.rightArrowGreen{
  width: 9px;
  /* height: 8px; */
  margin-left: 2px;
}

.callback{
  /* font-family: Inter; */
  font-size: 22px;
  font-weight: 700;
  line-height: 26.63px;
  
}

.guidanceOuter{
  display: flex;
  border-radius: 6px;
}

.innerBoxGuidance{
  margin-top: 30px;
  margin-left: 30px;
}

.struggleBox{
  font-size: 14px;
  line-height: 19.6px;
  font-weight: 400;
  color: #757575;
}

.guidanceRadio{
  /* font-family: Inter; */
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  text-align: center;
  display: inline-flex;
  align-items: center;
}

.guidanceRadio input[type="radio"] {
  margin-right: 5px; 
  accent-color: #02A699;
  --radio-hover-color: #02A699;
}

.guidanceRadio input[type="radio"]:hover {
  accent-color: var(--radio-hover-color);
}

input[type="radio"]:checked {
  /* accent-color: #02A699; */
}

.selectTimeDate{
  border-radius: 4px;
  padding: 4px;
  background-color: #FFF;
  font-size: 14px;
  font-weight: 400;
  max-height: 100px;
  overflow-y: auto;
}

.submitBtnHeader{
  border-radius: 4px;
  padding: 6px 25px;
  background-color: #007A6E;
  color: #FFF;
  float: right;
  font-size: 14px;
  margin-right: 30px;
  cursor: pointer;
  font-weight: 700;
}

.ourpart{
  position: relative;
}

.hoverDrop{
  display: none;
  position: absolute;
  background: #FFF;
  padding: 8px 0px;
  border-radius: 6px;
  min-width: 150px;
  box-shadow: 0px 4px 20px 0px #0000001A;
}

.ourpart:hover .hoverDrop{
  display: block !important;
  position: absolute;
  background: #FFF;
  padding: 8px 0px;
  border-radius: 6px;
  min-width: 150px;
  box-shadow: 0px 4px 20px 0px #0000001A;
}

.ourpart:hover .arrowIconHeader {
  transform: rotate(180deg);
}

.lendersList{
  padding: 5px 0px;
}

.ddContent:hover {
  font-weight: 700;
}

.appNowHeader:hover .rightArrowGreen{
  width: 15px;
}

.timeDrop{
  position: absolute;
  background: #FFF;
  border-radius: 6px;
  min-width: 100px;
  box-shadow: 0px 4px 20px 0px #0000001A;
  max-height: 80px;
  overflow-y: scroll;
  border: 1px solid;
  margin-left: 160px;
  margin-top: 31px;
  z-index: 1;
  font-size: 13px;
}

.timeDrop ::-webkit-scrollbar {
  display: none;
}

.exploreOther{
  align-items: center;
  display: flex;
  justify-content: center;
  background: #007A6E;
  max-width: 190px !important;
  margin:10px 0px ;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-family: Arial;
  font-style: normal;
  font-weight: 700;
  height: 41px;
  font-size: 16px;
  line-height: 18.4px;
  text-decoration: none;
}

.exploreOther:hover {
  text-decoration: none;
  color: #FFF;
}

.custom-modal-content {
  border-radius: 12px;
}

#allAppList::-webkit-scrollbar, 
#loanTimeline::-webkit-scrollbar,
#fileUploadLabel::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

#allAppList::-webkit-scrollbar-track, 
#loanTimeline::-webkit-scrollbar-track,
#fileUploadLabel::-webkit-scrollbar-track {
    background: rgb(200, 230, 255); 
    border-radius: 10px;
}

#allAppList::-webkit-scrollbar-thumb, 
#loanTimeline::-webkit-scrollbar-thumb,
#fileUploadLabel::-webkit-scrollbar-thumb {
    background: rgb(100, 160, 210); 
    border-radius: 10px;
}

#allAppList::-webkit-scrollbar-thumb:hover, 
#loanTimeline::-webkit-scrollbar-thumb:hover,
#fileUploadLabel::-webkit-scrollbar-thumb:hover {
    background: rgb(80, 140, 190); 
}

#custom-modal-bank .modal-content {
  align-items: flex-start;
  min-width: 800px;
  /* margin-left: -120px; */
  /* margin-top: 130px; */
  /* margin: 0 auto; */
}

#custom-modal-bank .modal-body {
  width: 100%;
  margin-top: 0px;
  padding-top: 0px;
}

#custom-modal-bank .btn-close {
  margin-top: -20px;
}

#custom-modal-bank .modal-header{
  margin: 0 auto;
}

/* #custom-modal-bank .lenderfilters .inputboxes {

  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  justify-content: space-between;
  border-radius: 6px;
  background: #F1F1F1;
  max-width: 280px !important;
  max-height: 50px !important;
  cursor: pointer;

} */

#fromDate.custom-input {
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  color: #333;
  width: 85px;
}

#toDate.custom-input {
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  color: #333;
  width: 85px;
}

#fromDate .custom-calendar {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

#fromDate .custom-calendar .react-datepicker__day--selected {
  background-color: #85BCF6;
  color: white;
}
.bkycDrpdownProp {
  position: absolute;
  left: 139px;
  top: -35px;
  min-width: 140px;
  background-color: #FFF;
  border: 1px solid grey;
  display: none;
}

.resiDrpdownProp {
  position: absolute;
  left: 133px;
  top: -25px;
  min-width: 140px;
  background-color: #FFF;
  border: 1px solid grey;
  display: none;
}

#resiProofProp:hover .resiDrpdownProp {
  display: block;
}

#resiProofProp:hover .resiDownArrProp {
  transform: rotate(-90deg);
  transition: transform 0.3s ease; 
}

#bkycProp:hover .bkycDrpdownProp {
  display: block;
}

#bkycProp:hover .bkycDownArrProp {
  transform: rotate(-90deg);
  transition: transform 0.3s ease; 
}

.resiDrpdownPartner {
  position: absolute;
  left: 163px;
  top: -15px;
  min-width: 140px;
  background-color: #FFF;
  border: 1px solid grey;
  display: none;
}

#resiProofPartner:hover .resiDrpdownPartner {
  display: block;
}

#resiProofPartner:hover .resiDownArrPartner {
  transform: rotate(-90deg);
  transition: transform 0.3s ease; 
}

.officeDrpdown {
  position: absolute;
  left: 163px;
  top: -25px;
  min-width: 140px;
  background-color: #FFF;
  border: 1px solid grey;
  display: none;
}
#officeProof:hover .officeDrpdown {
  display: block;
}

#officeProof:hover .officeDownArr {
  transform: rotate(-90deg);
  transition: transform 0.3s ease; 
}

.bkycDrpdownPartner {
  position: absolute;
  left: 163px;
  top: -25px;
  min-width: 150px;
  background-color: #FFF;
  border: 1px solid grey;
  display: none;
}

#bkycPartner:hover .bkycDrpdownPartner {
  display: block;
}

#bkycPartner:hover .bkycDownArrPartner {
  transform: rotate(-90deg);
  transition: transform 0.3s ease; 
}

.commonPartner {
  position: absolute;
  left: 163px;
  top: 0px; 
  min-width: 140px;
  background-color: #FFF;
  border: 1px solid grey;
  display: none;
}

#panPartner:hover .commonPartner {
  display: block;
}

#panPartner:hover .commonArrow {
  transform: rotate(-90deg);
  transition: transform 0.3s ease; 
}

#aadharPartner:hover .commonPartner {
  display: block;
}

#aadharPartner:hover .commonArrow {
  transform: rotate(-90deg);
  transition: transform 0.3s ease; 
}

#photoPartner:hover .commonPartner {
  display: block;
}

#photoPartner:hover .commonArrow {
  transform: rotate(-90deg);
  transition: transform 0.3s ease; 
}

.subCommonPartner {
  position: absolute;
  left: 139px;
  top: -1px;
  min-width: 190px;
  background-color: #FFF;
  border: 1px solid grey;
  display: none;
}

.hoverable-parent:hover .subCommonPartner {
  display: block;
}

.hoverable-parent:hover .subCommonArrow {
  transform: rotate(-90deg);
  transition: transform 0.3s ease;
}

.hoverable:hover {
  font-weight: 700;
  background-color: #f0f0f0;
}

.hoverable-parent .hoverable {
  font-weight: normal; 
}

.hoverable-parent:hover .hoverable:hover {
  font-weight: 700;
  background-color: #f0f0f0;
}

#resiProofPartner:hover .resiDownArrPartner {
  transform: rotate(-90deg);
  transition: transform 0.3s ease;
}

.kycDocsPartners{
  padding: 2px 4px 2px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.subDropdown{
  padding: 4px 8px;
}

.constTypeRadio{
  display: inline-flex;
  margin: 4px 10px;
}

.crmLoginDiv{
  font-size: 16px;
  text-align: center;
  margin: 10px auto;
  font-family: Manrope;
}

.form-comp {
    background-image: url("https://loans.imimg.com/desktop_react/src/Images/newHomeImages/newFormBG_updated.png");
    /* height: 696px; */
    width: 100%;
    margin-top: 45px;
    background-repeat: round;
    background-size: auto;
    padding-bottom: 40px;
    position: relative;
}


.form-comp .bg-text {
    font-family: Manrope;
    font-size: 35px;
    font-weight: 700;
    line-height: 60px;
    color: #1F2933;
    line-height: 1.4;
    /* place-self: center; */
    /* margin-top: 88px;
    min-width: 286px; */
    /* width: 55%;  */
}

.form-comp #bg-icon {
    height: 477px;
    margin-top: -40px;
}


.form-comp .form-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: fit-content;
    /* margin-top: 52px; */
    /* width: 61%; */
}

.form-comp .form {
    display: flex;
    flex-direction: column;
}

.form-comp .form-group {
    margin-bottom: 16px;
    /* height: 60px; */
}

.form-comp label {
    font-size: 16px;
    color: #7A7A7A;
    /* margin-bottom: 8px; */
    display: block;
    font-family: Arial;
    font-weight: 400;
    line-height: 26.1px;
    text-align: left;

}

.form-comp input[type="text"],
.form-comp select {
    width: 100%;
    padding: 20px 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    /* background-color: #EEEEEE; */
    cursor: pointer;
    height: 30px;
    padding-bottom: 0px;
}

.form-comp input[type="text"]::placeholder {
    color: #888;
}

.form-comp .input-with-icon {
    position: relative;
}

.form-comp .err {
    font-family: Arial;
    font-size: 13px;
    font-weight: 400;
    line-height: 16.1px;
    text-align: left;
    color: #EB5757;
    margin-top: 5px;
}

.form-comp .input-with-icon input {
    padding-right: 30px;
}

.form-comp .tick-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    color: #02A699;
    margin-right: 15px;
    /* Green check color */
}

.form-comp .phone-input {
    display: flex;
}

.form-comp .phone-input select {
    width: 25%;
    margin-right: 5px;
}

.form-comp .checkbox-group {
    display: flex;
    align-items: flex-start;
    font-size: 12px;
}

.form-comp .checkbox-group input[type="checkbox"] {
    margin-top: 4px;
    margin-right: 8px;
}

.form-comp .checkbox-group label a {
    color: #00aaff;
    text-decoration: none;
}

.form-comp .submit-btn {
    background-color: #007A6E;
    color: #fff;
    width: 100%;
    height: 48px;
    text-align: center;
    gap: 10px;
    border-radius: 6px;
    align-self: center;
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.4px;
    align-content: center;
    cursor: pointer;
}

.form-comp .submit-btn:hover {
    background-color: #01635b;
}

/* React Date Picker */
.react-datepicker-popper {
    left: -32% !important;
    top: 24px !important;
}

.react-datepicker {
    font-family: Arial, sans-serif;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
    border-bottom: 1px solid #ddd;
    border-radius: 8px 8px 0 0;
    padding: 10px;
}

.react-datepicker__current-month {
    display: none;
}

.react-datepicker__month-dropdown-container,
.react-datepicker__year-dropdown-container {
    display: inline-block;
    margin: 5px;
    font-size: 18px;
}

.react-datepicker__month-dropdown-container {
    margin-left: -10px;
}

.react-datepicker__year-dropdown-container {
    margin-left: 20px;
}

.react-datepicker__current-month {
    font-weight: bold;
    font-size: 16px;
}

.react-datepicker__day--selected {
    background-color: #4caf50;
    color: white;
    border-radius: 50%;
}

.react-datepicker__day:hover {
    background-color: #f0f0f0;
    color: black;
    border-radius: 50%;
}

.react-datepicker__month-dropdown{
    text-align: left;
    padding-left: 30px;
}

.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    max-height: 150px;
    overflow-y: auto;
    font-size: 14px;
}

.react-datepicker__month-dropdown-item:hover,
.react-datepicker__year-dropdown-item:hover {
    background-color: #f0f0f0;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    transform: rotate(135deg);
    right: -16px;
    top: 3px;
}

.form-comp .checkbox-container {
    display: flex;
    align-items: center;
}

.form-comp input[type="checkbox"] {
    display: none;
}

.form-comp input[type="checkbox"]+label {
    width: 16px;
    height: 16px;
    display: inline-block;
    background-color: #e0e0e0;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease;
}

.form-comp input[type="checkbox"]:checked+label {
    background-color: #00B2A4;
    border-color: #00B2A4;
}

.form-comp input[type="checkbox"]:checked+label::before {
    content: "✔";
    color: white;
    position: absolute;
    left: 2px;
    top: -4px;
    font-size: 14px;
}

.form-comp .declaration {
    font-family: Inter;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: -0.23999999463558197px;
    text-align: left;
    margin-left: 16px;

}

.feature {
    background-color: #ffffff;
    margin-top: 50px;
    margin-bottom: 50px;
}

.feature .featureBoxes {
    background: #F6F9FA;
    margin-left: 20%;
    padding: 32.5px;
}

.feature .featureBox {
    width: 100%;
    height: 220px;
    padding: 40px 30px 40px 30px;
    gap: 50px;
    border-radius: 12px 0px 0px 0px;
    background-color: #ffffff;
}

.feature .featureBoxImage {
    height: 34.2px;
}

.feature .featureBoxText {
    font-family: Arial;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.2px;
    letter-spacing: -0.34315788745880127px;
    text-align: left;
    color: #1B1D1D;
    width: 108%;
    gap: 0px;
    margin-top: 50px;
}

.feature #featureH1 {
    font-family: Manrope;
    font-size: 28px;
    font-weight: 600;
    line-height: 36.4px;
    text-align: left;
    width: 245px;
    margin-top: 15px;
}

.feature #featurePara {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.86px;
    text-align: left;
    width: 100%;
    color: #757575;
    margin-top: 25px;
    max-width: 424px;
}

.feature #coinsIcon {
    height: 188px;
    margin-top: 71px;
}

.feature .betterBox {
    margin-left: 20%;
}

.feature .get-btn {
    width: 145.17px;
    height: 48px;
    gap: 14.14px;
    border-radius: 6px;
    background-color: #007A6E;
    color: #FFFFFF;
    text-align: center;
    align-content: center;
    margin-top: 32px;
    cursor: pointer;
}

.few-clicks{
    margin: 0px auto 10px;
    font-size: 24px;
    color: #1C1C1C;
    font-weight: 700;
    /* width: 70%; */
    text-align: center;
    min-width: 206px;
}

#click-captured .thanksText{
    font-family: Arial;
    font-size: 22px;
    font-weight: 700;
    line-height: 25.3px;
    color: #02A699;
    margin: 15px 0px 10px 0px;
}

#click-captured .reach-out{
    font-family: Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #7A7A7A;
    width: 80%;
    margin: 0 auto;
}

#click-captured .three-offers-box{
    background-color: #F4FAFB;
    text-align: center;
    padding: 20px;
    margin-top: 80px;
    border-radius: 6px;
}

#click-captured .what-we-offer{
    font-family: Arial;
    font-size: 16px;
    font-weight: 700;
    line-height: 18.4px;
    color: #1C1C1C;
}

#click-captured .offer-icon{
    width: 30px;
    height: auto;
}

#click-captured .offer-text{
    font-family: Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #1C1C1C;
    margin-top: 4px;
}

#click-captured .offer-boxes{
    width: 40%;
}

.left-circle {
    background-color: #F4FAFB;
    width: 70px;
    height: 100px;
    /* border-radius: 50%; */
    position: absolute;
    top: -10px; 
    left: -10px; 
    border-bottom-right-radius: 100px;
}

.right-circle {
    background-color: #F4FAFB;
    width: 80px;
    height: 60px;
    /* border-radius: 50%; */
    position: absolute;
    top: -10px;
    right: -10px; 
    border-bottom-left-radius: 100px;
}

.busi-grow {
    background: linear-gradient(270.8deg, #00B2A4 18.23%, #105EA9 99.32%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.quick-line{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #4D4D4D;
    margin-top: 26px;
}

.param-value{
    font-family: Manrope;
    font-size: 26px;
    font-weight: 700;
    line-height: 45px;
    color: #1C1C1C
}

.param-text{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #4D4D4D
}

.white-boxes{
    background: #FFFFFF99;
    border: 1px solid #D8E9E8;
    box-shadow: 0px 4px 10px 0px #DBF2F1 inset;
    display: flex;
    border-radius: 10px;
    padding: 16px 28px;
    max-width: 388px;
    margin-top: 50px;
    width:80%;
}

.scroll-benefit{
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #797979;
    position: absolute;
    /* left: 65px; */
    bottom: 5px;
}

#partner-carousel .lendersBoxStyle {
    border-radius: 8px;
    border: 1px solid #D5EAFF;
    background: #FFF;
    background-blend-mode: soft-light;
    box-shadow: 3px 6px 44px 0px rgba(46, 49, 146, 0.04);
    height: 50px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    /* width: 90%; */
}

.footerBoxStyle{
    width: 95%;
    border-radius: 24px;
    border: 1px solid #DCDCDC;
    background: #FFF;
    padding: 20px;
    height: 110px;
    flex-shrink: 0;
    overflow: hidden;
}

.custom-slider .slick-list {
    padding: 0 !important;
}

#partner-carousel .headingStyle{
    font-style: normal;
    margin-top: -8px;
    text-transform: capitalize;
    color: #909090;
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 1px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

#partner-carousel .custom-slider .slick-prev,
#partner-carousel .custom-slider .slick-next {
    margin-top: -60px;
}

#partner-carousel .custom-slider .slick-prev {
  left: -30px;
}

#partner-carousel .custom-slider .slick-next {
  right: -15px;
}

#partner-carousel .custom-slider .slick-prev i,
#partner-carousel .custom-slider .slick-next i {
  font-size: 24px;
  color: black;
}

#partner-carousel .custom-slider .slick-prev::before,
#partner-carousel .custom-slider .slick-next::before {
  display: none;
}

.feature-tick{
    color: #00B2A4;
    font-size: 22px;
    opacity: 0.7;
}

.feature-text{
    font-family: Manrope;
    font-size: 18px;
    font-weight: 500;
    line-height: 27.32px;
    letter-spacing: -0.23999999463558197px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #242424;
    margin-left: 16px;
    margin-top: 3px;
}

.loan-feature{
    display: flex;
}

.one-stop{
    font-family: Manrope;
    font-size: 16px;
    margin-top: 8px;
}





  